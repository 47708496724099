$nau-trueblue:        #002454 !default;
$nau-gold:        #FAC01A !default;

$nau-snowplow:    #C3B8B2 !default;
$nau-snowplow-light:    #DED8D5 !default;
$nau-snowplow-lighter:    #F0EDEB !default;
$nau-snowplow-lightest:    #F9F7F6 !default;

$nau-plum:        #61003d !default;
$nau-yellow:      #ffd200 !default;
$nau-orange:      #e86e25 !default;
$nau-green:       #00853f !default;
$nau-light-blue:  #99cccc !default;
$nau-gray-blue:   #00274d !default;
$nau-gray:        #606269 !default;
$nau-gray-dark:   #2f3034 !default;
$nau-gray-darker: #e1dad8 !default;
$nau-gray-darkest:#ede9e7 !default;
$nau-blue-alt:    #0057ab !default;

$nau-gray-alt:    #f0f0f0 !default;

$nau-morning-grey: #97CBCD !default;

$nau-monsoon:     #C3B8B2 !default;
$nau-monsoon-light:     lighten( $nau-monsoon, 25% )!default;
$nau-monsoon-lighter:     lighten( $nau-monsoon, 50% )!default;
$nau-monsoon-dark:     #908681 !default;
$nau-monsoon-darker:     #64564F !default;
$nau-summershade:       #00853f !default;
$nau-fallaspen:      #ffd200 !default;
$nau-sunset:      #e86e25 !default;
$nau-supai:    #00ADB5 !default;
$nau-redrock:    #E86D1F !default;
$nau-sky:    #009DDC !default;
$nau-twilight:    #0057ab !default;


$directory-bg: #D6E3E1;

