@import "./styles-nau-variables.scss";

/* NAU brand overrides for Bootstrap */

$body-color: $nau-gray-dark;

$font-family-sans-serif:  "acumin-pro" !default;
$font-size-base:          1.3rem !default; // Assumes the browser default, typically `16px`

$headings-font-family:  "acumin-pro-condensed" !default;
$headings-font-weight:  700 !default;
$headings-color:        $nau-trueblue !default;

$h1-font-size: 48px !default;
$h2-font-size: 36px !default;
$h3-font-size: 30px !default;
$h4-font-size: 21px !default;
$h5-font-size: 20px !default;
$h6-font-size: 12px !default;

$link-color:              #000421 !default;
$link-decoration:         underline !default;
$link-hover-color:        #0057ab;
$link-hover-decoration:   underline !default;


$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "nau-trueblue":         $nau-trueblue,
    "nau-gold":         $nau-gold,
    "nau-snowplow":       $nau-snowplow,
    "nau-snowplow-light":       $nau-snowplow-light,
    "nau-snowplow-lighter":       $nau-snowplow-lighter,
    "nau-snowplow-lightest":       $nau-snowplow-lightest,
    "nau-monsoon":      $nau-monsoon,
    "nau-monsoon-light":      $nau-monsoon-light,
    "nau-monsoon-lighter":      $nau-monsoon-lighter,
    "nau-monsoon-dark": $nau-monsoon-dark,
    "nau-monsoon-darker": $nau-monsoon-darker,
    "nau-summershade":  $nau-summershade,
    "nau-fallaspen":    $nau-fallaspen,
    "nau-sunset":       $nau-sunset,
    "nau-supai":        $nau-supai,
    "nau-redrock":      $nau-redrock,
    "nau-sky":          $nau-sky,
    "nau-twilight":     $nau-twilight,
    "nau-plum":         $nau-plum,
    "nau-yellow":       $nau-yellow,
    "nau-orange":       $nau-orange,
    "nau-green":        $nau-green,
    "nau-light-blue":   $nau-light-blue,
    "nau-gray-blue":    $nau-gray-blue,
    "nau-gray":         $nau-gray,
    "nau-gray-dark":    $nau-gray-dark,
    "nau-gray-darkest":    $nau-gray-darkest,
    "nau-blue-alt":     $nau-blue-alt
  ),
  $theme-colors
);

$btn-border-radius: 0;
$btn-font-weight:   600;

$table-accent-bg: #f9f9f9;
$table-bg: #FFF;
$table-color: $nau-trueblue;
$table-striped-order: even;

$treebg: #eaeef7;

@import "bootstrap/scss/bootstrap";


/* end Bootstrap override */
body {
  line-height: 1.8em;
  font-size: 16px;
  color: #002454;
}

h1 {
  color: #000421;
  font-family: "acumin-pro" !important;
  font-size: 2em;
  line-height: 1.2em;
}

/* Use for tab titles (i.e. "All, Faculty, Staff, Departments"): */
h2 {
  color: #000421;
  font-family: "acumin-pro";
  font-size: 1.75rem;
  line-height: 1.2em;
}

/* Use for names in large cards: */
h3 {
  color: #000421;
  font-family: "acumin-pro";
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2em;
}

/* Use for subtitles in large card results (i.e. "Administration"): */
h4 {
  color: #000421;
  font-family: "acumin-pro";
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.375rem;
}

/* Use for form field labels: */
h5 {
  color: #000421;
  font-family: "acumin-pro";
  font-size: 1em;
  line-height: 1.2em;
  font-weight: 500;
}

h6 {
  color: $nau-trueblue;
  font-family: "acumin-pro";
  font-size: 14px;
  font-weight: 700;
  line-height: 1rem;
  
}

@mixin nauButton($color, $secondaryColor, $outlineColor) {
  color: $color;
  background-color: $secondaryColor;
  font-family: "acumin-pro";
  font-size: 0.9375rem;
  padding: 0.75rem 1.375rem;
  display: inline-block;
  border-radius: 2px;
  line-height: 1.2;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:hover:not(:disabled), &:focus {
    background-color: $secondaryColor;
    transform: scale(1.03);
    text-decoration: none;
    outline-color: $outlineColor;
    outline-width: 3px;
    outline-style: solid;
    outline-offset: -3px;
  }
  &:disabled {
    background-color: #d9d5d2 ;
    transform: none;
  }
}

.btn {
  font-family: "acumin-pro";
  color: $nau-trueblue;
}

.btn.btn-link {
  font-family: "acumin-pro";
}

.btn.btn-link.condensed {
  font-family: "acumin-pro-condensed";
}

.btn.btn-nau {
  @include nauButton($nau-trueblue, #fdcf0f,#fdcf0f);
}

.btn.btn-nau-secondary  {
  @include nauButton($nau-monsoon-darker, $nau-snowplow-lighter,$nau-snowplow-lighter);
  box-shadow: 0.15em 0.15em 0 0 $nau-monsoon;
}

.btn.btn-nau-tertiary  {
  @include nauButton(#FFF, transparent, transparent);
  outline: 1px solid #FFF;
}

.btn.btn-nau-delete {
  @include nauButton(#B35B20, transparent,#B35B20);
  outline: 3px solid #B35B20;
}

.btn.btn-nau-cancel {
  @include nauButton($nau-trueblue, transparent,$nau-trueblue);
  outline: 3px solid #001632;
}

.materialAutoCompleteStyles{
  width:350px !important
}

.form-control, .input-group-prepend {
  border-radius: 0px;
}

.btn.closeModal {
  top: 0px;
  right: -1px;
  position: absolute;
  text-decoration: none;
  padding-right: 0.5em;
  padding-top: 0px;
}

 a:focus, .btn:focus, div#mainContent:focus, .form-control:focus {
  outline-width: 2.5pt !important;
  outline-color: #3b99fc !important;
  outline-style: solid !important;
  outline-offset: 1px !important;
}

.btn:focus {
  z-index: 10 !important;
}

.dropdown-menu.show {
  z-index: 100 !important;
}

div#mainContent:focus {
  outline-offset: -3px !important;
}

a {
  cursor: pointer;
}


.dropdown-item > .alt-text {
  color: $nau-gray !important;
  font-size: medium;
}

.dropdown-item.active > .alt-text {
  color: $nau-monsoon-lighter !important;
}


a#skip-to-content-link.sr-only:focus {
  background: #fff;
  clip: auto!important;
  display: block;
  font-size: 1em;
  font-weight: 700;
  height: auto;
  width: auto;
  padding: 15px 25px;
  z-index: 9999;
  margin-top: 50px;
}

#content-wrapper {
  min-height: calc(100vh - 117px);
}

::ng-deep .dropdown-menu.show {
  width: 100%;
  overflow-wrap: break-word;
  max-height: 350px !important;
  overflow-y: auto !important;
  button {
    font-size: 18px;
    line-height: 22px;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }
}
::ng-deep .dropdown-menu.show .dropdown-item {
  white-space: normal;
  overflow-wrap: word;
  padding-top: 7px;
  padding-bottom: 7px;
}


@media only screen and (max-width: 480px) {
  body {
    font-size: 16px;
  }
}

@include media-breakpoint-up(sm) {
  .contact-container {
    margin-left: 35px;
    margin-right: 35px;
  }
}

@include media-breakpoint-up(md) {
  .contact-container {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@include media-breakpoint-up(lg) {
  .contact-container, .info-section-container {
    margin-left: 45px;
    margin-right: 45px;
  }
}


@include media-breakpoint-up(xl) {
  .contact-container {
    margin-left: auto;
    margin-right: auto;
  }
  .contact-container {
    width: 1200px;
  }
  .info-section-container {
    width: 1200px;
  }
}

#nau-main-desktop-nav {
  font-size: 14px;
  line-height: 1.4285em;
}
#nau-main-desktop-nav .form-control {
  height:auto;
}
.modal {
  top: calc(25vh);
}
.btn:hover {
  background-color: transparent;
}

.admin-tooltips .tooltip-inner {
  min-width: min-content;
  z-index: 9999;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: #fff;
  padding: 0.833em 1em;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  text-align: left;
  color: rgba(0,0,0,.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34,36,38,.12);
}
.admin-tooltips.tooltip.show {
  opacity: .95;
}
.admin-tooltips.bs-tooltip-right .arrow::before {
  border-right-color: #fff;
}
.admin-tooltips.bs-tooltip-left .arrow::before {
  border-left-color: #fff;
}
.admin-tooltips.bs-tooltip-top .arrow::before {
  border-top-color: #fff;
}
.admin-tooltips.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff;
}

footer#site-footer {
  line-height: 1.5;

  #campus-locator {
    #select-find {
      .h5 {
        @media screen and (min-width: 1650px) {
          font-size: 1.25rem;
          line-height: 1.25rem;
        }

        color: white;
        font-size: 18px;
        font-family: 'acumin-pro', sans-serif;
        margin-bottom: 1rem;
      }

      .select-find-grouper {
        #campus-location-select {
          padding: 0.875rem 0.75rem;
          line-height: 1.25rem;
          border: 1px solid #49679A;
          font-size: 100%;
          height: 50px;
        }
      }
    }

    #show-location {
      .location-group {
        #campus-location-name {
          color: white;
        }

        address {
          #campus-location-phone {
            text-decoration: none;
          }
          margin-bottom: 20px !important;
        }
      }

      #contact-campus-maps {
        a {
          font-size: 0.9375rem;
          display: block;
          padding: 0.75rem 1.375rem;
          border-radius: 2px;
          line-height: 1.2;
        }

        #campus-location-all {
          color: white;
        }
      }
    }
  }

  #helpful-links {
    .h5 {
      @media screen and (min-width: 1650px) {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }

      color: white;
      font-size: 18px;
      font-family: 'acumin-pro', sans-serif;
      margin-bottom: 1rem;
    }
  }
}

.modal-backdrop {
    z-index: 0 !important;
}
